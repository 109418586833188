import React, { FC } from 'react';
import { Button, Card, Container, Image, Text } from '@legalshield/adonis-ux-framework';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { SubscriptionDetails } from './SubscriptionDetails';
import { formatDate, isAssociate, isLegal } from '../../utils/utils';
import { getBillingFrequency } from '../../utils/getBillingFrequency';
import { getFriendlyProductName } from '../../utils/getFriendlyName';
import { useLaunchDarkly } from '../../../../hooks/useLaunchDarkly';

import './Subscription.scss';
import { useNavigate } from 'react-router-dom';

interface SubscriptionProps {
  subscription?: ISubscriptionResource;
  isLoading?: boolean;
}

interface SubscriptionPreviewProps {
  logo: string;
  price: string;
  productName: string;
  realmDomain: string;
  subtitle: string;
  showUpgradeButton: boolean;
  onUpgradeClick?: () => void;
}

const SubscriptionPreview: FC<SubscriptionPreviewProps> = ({
  logo,
  price,
  productName,
  realmDomain,
  subtitle,
  showUpgradeButton,
  onUpgradeClick,
}) => {
  const { upgradeFlow } = useLaunchDarkly();

  return (
    <div className="accounts-subscription-preview">
      <Container flexbox flexDirection="row">
        <Container>
          <Image
            id="product-logo"
            classNames={['mr-4']}
            height={32}
            width={32}
            src={'https://design.api.' + realmDomain + '/assets/logos/' + logo + '-square-logo.svg'}
            alt={string_table.PRODUCT_LOGO_ALT}
          />
        </Container>
        <Container flexbox flexDirection="column" alignItems="flex-start">
          <Text textSize="extra-large" id="subscription-name" text={productName} />
          <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
            <Text textSize="large" classNames={['accounts-subscription-item__header-price', 'pr-2']}>
              {price}
            </Text>
            <Text textSize="medium" classNames={['accounts-subscription-item__header-bill-date']}>
              {subtitle}
            </Text>
          </Container>
        </Container>
      </Container>
      {upgradeFlow && showUpgradeButton && (
        <Container flexbox flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
          <Button label="Upgrade" onClick={onUpgradeClick} />
        </Container>
      )}
    </div>
  );
};

const SubscriptionPreviewSkeleton: FC = () => (
  <>
    <Container>
      <Skeleton width={32} height={32} className="mr-4" />
    </Container>
    <Container flexbox flexDirection="column" alignItems="flex-start">
      <Skeleton count={1} height={28} width={260} />
      <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
        <Skeleton count={1} height={22} width={160} className="accounts-subscription-item__header-price mr-2" />
        <Skeleton count={1} height={18} width={160} className="accounts-subscription-item__header-bill-date" />
      </Container>
    </Container>
  </>
);

export const Subscription: FC<SubscriptionProps> = ({ subscription, isLoading }) => {
  const nextBillOn = formatDate(subscription?.nextBillOn);

  const isLegalPlan = isLegal(subscription?.productBrochure?.productFamily);
  const isAssociatePlan = isAssociate(subscription?.productBrochure?.productFamily);
  const logo: string = isLegalPlan ? 'legalshield' : isAssociatePlan ? 'pplsi' : 'idshield';
  const billingPeriod = getBillingFrequency(subscription?.billingFrequency.period);
  const price = `$${subscription?.price?.toFixed(2)} ${string_table.PRODUCT_BILL_FREQUENCY} ${billingPeriod}`;

  // Determine if the subscription is upgradable
  const isUpgradable = isLegalPlan === true;

  const navigate = useNavigate();

  return (
    <Card key={subscription?.id} classNames={['accounts-subscription', 'my-4']}>
      <Container flexDirection="row" flexbox alignItems="flex-start" classNames={['m-5']}>
        {isLoading ? (
          <SubscriptionPreviewSkeleton />
        ) : (
          <SubscriptionPreview
            logo={logo}
            price={price}
            productName={getFriendlyProductName(subscription)}
            realmDomain={pplsi.realmDomain}
            subtitle={`${string_table.PRODUCT_NEXT_BILL_DATE} ${nextBillOn}`}
            // TODO: Wire up this button to the upgrade functionality
            showUpgradeButton={isUpgradable}
            onUpgradeClick={() => {
              navigate(`/payments/${subscription?.id}/upgrade`);
            }}
          />
        )}
      </Container>
      <div className="accounts-subscription-item__content p-5">
        <SubscriptionDetails isLoading={isLoading} subscription={subscription} />
      </div>
    </Card>
  );
};
