import React, { FC, useState } from 'react';

import { Layout } from '../../Layout/Layout';
import { Container, Text, Button, Spinner } from '@legalshield/adonis-ux-framework';

import './UpgradePlan.scss';
import { UpgradePlanSkeleton } from '../../UpgradePlanSkeleton/AvailablePlanSkeleton';
import AvailablePlan from '../../AvailablePlan/AvailablePlan';
import { getFriendlyProductName } from '../utils/getFriendlyName';
import { Navigate, useParams } from 'react-router-dom';
import { useGetSubscriptionBySubscriptionId } from '../hooks/useSubscription';

const UpgradePlan: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const { data: subscription, isLoading: subscriptionsLoading } = useGetSubscriptionBySubscriptionId(id);

  if (subscriptionsLoading) return <Spinner blocking spinnerSize="xlarge" />;
  if (!subscription) {
    return <Navigate to="/payments" replace />;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_TITLE, link: '/overview' },
    { label: string_table.PAYMENT_TITLE, link: '/payments' },
    { label: getFriendlyProductName(subscription) },
    { label: string_table.UPGRADE_PLAN_TITLE, link: '/payments/:id/upgrade' },
  ];

  const preferredFeatures: string[] = [
    'Annual updates to your Will',
    'Navigate an uncontested divorce',
    'Protect marital assets with a prenuptial agreement or uncontested separation/annulment',
    'Uncontested adoption',
    'Review and negotiate rental leases as a tenant',
    'Reinstate your driver’s license',
    'Review mortgage documents',
    'Collect vehicle property damages',
  ];

  const premiumFeatures: string[] = [
    'Establish a trust',
    'Assistance with a simplified probate',
    'Defense on death-related traffic charges',
  ];

  return (
    <Layout
      crumbs={crumbs}
      title={string_table.UPGRADE_PLAN_PAGE_TITLE}
      subtitle={string_table.UPGRADE_PLAN_PAGE_SUBTITLE}
    >
      <Container flexDirection="column" flexbox justifyContent="center" classNames={['mt-6']}>
        {isLoading ? (
          <UpgradePlanSkeleton />
        ) : (
          <>
            <Text text={string_table.UPGRADE_PLAN_TITLE_DESCRIPTION} classNames={['mb-5']} />
            <Container classNames={['mt-5', 'upgrade-plan__desktop', 'upgrade-plan__mobile']}>
              <AvailablePlan
                showPlanExplanation
                classNames={['upgrade-plan__available_plan']}
                title="Preferred"
                subtitle="Ideas for families"
                price="37.95"
                features={preferredFeatures}
                buttonLabel="Upgrade"
                linkLabel="View additional services"
                buttonIsDisabled={false}
                planTerm="/mo."
                mobilePlanDescription="Speak with a lawyer on an unlimited number of personal legal issues."
                featureType="Basic"
              />
              <AvailablePlan
                classNames={['upgrade-plan__available_plan']}
                title="Premium"
                subtitle="Comprehensive protection for the entire family"
                price="49.95"
                features={premiumFeatures}
                buttonLabel="Upgrade"
                linkLabel="View additional services"
                showIncludesFeatures
                buttonIsDisabled={false}
                featureType="Advanced"
                planTerm="/mo."
                mobilePlanDescription="Speak with a lawyer on an unlimited number of personal legal issues and get enhanced legal support for your family."
              />
            </Container>
          </>
        )}
        <Container flexbox flexDirection="column" classNames={['upgrade-plan__footer']}>
          <Button
            classNames={['upgrade-plan__compare_plans_button', 'compare_plans__mobile']}
            variant="primary"
            label={string_table.COMPARE_PLANS}
            stretch
          />
          <Text text={string_table.UPGRADE_PLAN_FOOTER_TEXT} textSize="small" classNames={['mt-6']} />
          <Button
            classNames={['upgrade-plan__subscription_button', 'subscription_button__mobile']}
            variant="secondary"
            label={string_table.KEEP_SUBSCRIPTION}
            stretch
          />
          <p className="mt-5 upgrade-plan__footer_text">
            {string_table.UPGRADE_PAGE_FOOTER_TEXT}
            <span className="upgrade-plan__footer_number"> (844) 714-8494</span>
          </p>
        </Container>
      </Container>
    </Layout>
  );
};

export default UpgradePlan;
