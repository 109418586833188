import React, { FC, useState } from 'react';
import { Button, Card, Container, Icon, Text } from '@legalshield/adonis-ux-framework';
import cn from 'classnames';

import './AvailablePlan.scss';
import { Link } from 'react-router-dom';

interface AvailablePlanProps {
  buttonIsDisabled?: boolean;
  showPlanExplanation?: boolean;
  showIncludesFeatures?: boolean;
  isSelected?: boolean;
  title?: string;
  subtitle?: string;
  price?: string;
  features?: string[];
  buttonLabel?: string;
  buttonOnClick?: () => void;
  linkLabel?: string;
  linkHref?: string;
  featureType?: string;
  planTerm?: string;
  classNames?: cn.Argument;
  mobilePlanDescription?: string;
}

const AvailablePlan: FC<AvailablePlanProps> = ({
  buttonIsDisabled,
  showPlanExplanation,
  showIncludesFeatures,
  isSelected,
  title,
  subtitle,
  price,
  features,
  buttonLabel,
  buttonOnClick,
  linkLabel,
  linkHref,
  featureType,
  planTerm,
  classNames,
  mobilePlanDescription,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Card classNames={['available-plan__card', isSelected && 'disabled_card', classNames]}>
      <Card.Content classNames={['available-plan__content']}>
        <Text text={title} classNames={['available-plan__title']} />
        <Text text={subtitle} classNames={['available-plan__subtitle']} />
        <Text text="Starting at" classNames={['available-plan__price_label']} />
        <div className="available-plan__price_wrapper">
          <Text text={'$' + price} classNames={['available-plan__price']} />
          <Text text={planTerm} classNames={['available-plan__price_quarter']} />
        </div>
        <Button
          classNames={['mt-5']}
          variant="primary"
          label={buttonLabel}
          disabled={buttonIsDisabled}
          onClick={buttonOnClick}
        />

        <Text
          text={mobilePlanDescription}
          classNames={[
            'show_content_mobile',
            'mt-8',
            'available-plan__hide_content_desktop',
            'available-plan__mobile_plan_description',
          ]}
        />

        <Container classNames={[isExpanded ? '' : 'hidden_content_mobile', 'available-plan__footer_link_bg_color']}>
          <hr className="available-plan__modal_divider" />
          <Text text="Legal services" classNames={['mb-4']} textWeight="semibold" />
          {showIncludesFeatures && (
            <div className="available-plan__custom_container">
              <Icon name="warning_circle_check_filled" />
              <p className="available-plan__custom_container_text">
                Includes all <b>{featureType}</b> features
              </p>
            </div>
          )}
          {showPlanExplanation && (
            <p className="available-plan__list_text_explanation">
              Document review, document creation, and legal letters for the following:
            </p>
          )}

          <>
            {features?.map((feature, index) => (
              <div className="available-plan__list" key={index}>
                <Icon name="interface_check" />
                <p className="available-plan__list_text">{feature}</p>
              </div>
            ))}
          </>
        </Container>
      </Card.Content>

      <Container
        classNames={[
          isExpanded ? '' : 'hidden_content_mobile',
          'available-plan__footer_link_container',
          'available-plan__footer_link_bg_color',
        ]}
      >
        <Link to={linkHref} className="available-plan__footer_link">
          <Container
            alignItems="center"
            flexbox
            flexDirection="row"
            classNames={['pt-4', 'available-plan__footer_link_bg_color']}
          >
            <Text
              text={linkLabel}
              textSize="medium"
              textWeight="semibold"
              classNames={['available-plan__footer_link_text']}
            />
            <Icon name="arrow_caret_right_sm" color="BRAND200" />
          </Container>
        </Link>

        <Link to={'#'} className="available-plan__footer_link">
          <Container
            alignItems="center"
            flexbox
            flexDirection="row"
            classNames={['pt-4', 'available-plan__footer_link_bg_color']}
          >
            <Text
              text={string_table.AVAILABLE_PLAN_VIEW_CONTRACT}
              textSize="medium"
              textWeight="semibold"
              classNames={['available-plan__footer_link_text']}
            />
            <Icon name="arrow_caret_right_sm" color="BRAND200" />
          </Container>
        </Link>
      </Container>

      <Container
        classNames={[
          'pt-4',
          'available-plan__footer_link_bg_color',
          'available-plan__hide_content_desktop',
          'footer_link_container__mobile',
          'see_details_link__mobile',
        ]}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Icon name="arrow_caret_right_sm" color="BRAND200" />
        {isExpanded ? (
          <Text
            text={string_table.AVAILABLE_PLAN_SEE_LESS}
            textSize="medium"
            textWeight="semibold"
            classNames={['available-plan__footer_link_text']}
          />
        ) : (
          <Text
            text={string_table.AVAILABLE_PLAN_SEE_DETAILS}
            textSize="medium"
            textWeight="semibold"
            classNames={['available-plan__footer_link_text']}
          />
        )}
      </Container>
    </Card>
  );
};

export default AvailablePlan;
