import React, { useMemo, useState } from 'react';
import { useHandleUpdatePaymentMethod } from './hooks/useUpdatePaymentMethod';
import { useNavigate } from 'react-router-dom';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import { Badge, Button, Dropdown, LinkContentProps, Spinner } from '@legalshield/adonis-ux-framework';
import {
  SubscriptionStatus,
  UpdatePaymentMethod,
  FriendlyIdType,
} from '@legalshield/frontend-commons/dist/sdk/subscriptions';

import { ISubscriptionResource } from './hooks/utils/subscription-utils';
import { formatCardType, isBankAccount } from './utils/utils';
import { populateTemplateString } from '../../utils/stringFormat';
import { useGetGroupByGroupId } from './hooks/useGetGroup';
import { useGetPaymentMethods } from './hooks/usePaymentMethods';
import { usePrecancelModal } from './PrecancelModal/PrecancelModalContext';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';

type PaymentDropdownProps = {
  subscription: ISubscriptionResource;
};

const PaymentDropdown = ({ subscription }: PaymentDropdownProps) => {
  const { newSubscriptionsUi } = useLaunchDarkly();
  const { data: paymentMethods, isLoading } = useGetPaymentMethods();
  const { id: subscriptionId } = subscription;
  const {
    mutateAsync: updatePaymentMethod,
    isError,
    isLoading: updatePaymentInProgress,
  } = useHandleUpdatePaymentMethod(subscriptionId);
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState<string | null>(subscription.paymentMethodId);

  const { data: groupData } = useGetGroupByGroupId(subscription?.source?.groupId);
  const isSelfPay = groupData?.payType === PayType.SELF_PAY || !subscription?.source?.groupId;
  const isAssociate = subscription?.source.friendlyIdType === FriendlyIdType.ASSOCIATE;
  const isPrecancel = subscription?.status === SubscriptionStatus.PRECANCEL;

  const { dispatch } = usePrecancelModal();
  const navigate = useNavigate();

  const addPaymentOption: LinkContentProps = {
    icon: 'action_add',
    onClick: () => {
      navigate({
        pathname: newSubscriptionsUi ? '/payments/wallet' : '/wallet',
        search: `?add_payment=Y&subscriptionId=${subscription?.id}&callback_url=${
          window.location.origin + `/payments`
        }&member=${subscription.source.friendlyId}`,
      });
    },
    role: 'option',
    text: string_table.ADD_PAYMENT_METHOD,
  };

  const managePaymentOption: LinkContentProps = {
    icon: 'edit_edit_pencil_01',
    onClick: () => {
      navigate({
        pathname: newSubscriptionsUi ? '/payments/wallet' : '/wallet',
      });
    },
    role: 'option',
    text: string_table.MANAGE_PAYMENT_METHODS,
  };

  const handlePaymentMethodClick = (paymentMethodId: string, subscriptionStatus: SubscriptionStatus) => {
    if (subscriptionStatus === SubscriptionStatus.PRECANCEL) {
      const handleClickPayAndRenew = () => {
        setCurrentPaymentMethodId(paymentMethodId);
      };
      dispatch({
        payload: {
          handleClickPayAndRenew,
          paymentMethodId: paymentMethodId,
          subscription,
        },
        type: 'SHOW_MODAL',
      });
    } else {
      const updatedPaymentMethod: UpdatePaymentMethod = {
        paymentMethodId: paymentMethodId,
      };

      updatePaymentMethod(updatedPaymentMethod);
      setCurrentPaymentMethodId(paymentMethodId);
    }
  };

  const paymentOptionsList = useMemo(() => {
    if (!paymentMethods) {
      return [];
    }

    const formatWithSlash = (date?: string) => {
      if (!date || date.length !== 4) return '';

      return date.slice(0, 2) + '/' + date.slice(2);
    };

    return paymentMethods
      ?.map((paymentMethod) => {
        const creditCardNumber = paymentMethod?.creditCard?.cardNumber;
        const bankAccountType = () => {
          if (!paymentMethod?.bankAccount) return;
          return paymentMethod?.bankAccount?.bankAccountType === 'CHECKING'
            ? string_table.BANK_ACCOUNT_TYPE_CHECKING
            : string_table.BANK_ACCOUNT_TYPE_SAVINGS;
        };
        const paymentSelectionText = isBankAccount(paymentMethod)
          ? `${bankAccountType()} - ${paymentMethod?.bankAccount?.accountNumber?.slice(-4)}`
          : `${formatCardType(paymentMethod?.creditCard?.cardType)} - ${creditCardNumber?.slice(
              -4,
            )} ${populateTemplateString(string_table.SUBSCRIPTION_EXP_DATE, {
              Exp: formatWithSlash(paymentMethod?.creditCard?.expirationDate),
            })}`;

        const linkContent: LinkContentProps = {
          id: paymentMethod?.id,
          key: paymentMethod?.id,
          onClick: () => {
            if (!isError) {
              handlePaymentMethodClick(paymentMethod?.id, subscription?.status);
            }
          },
          role: 'option',
          text: paymentSelectionText,
        };
        return linkContent;
      })
      .concat(addPaymentOption, managePaymentOption);
  }, [paymentMethods, isError, subscription]);

  const defaultPaymentLabel = useMemo(() => {
    const selectedPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod?.id === currentPaymentMethodId);
    if (selectedPaymentMethod) {
      const selectedPaymentOption = paymentOptionsList?.find(
        (paymentOption) => paymentOption.id === selectedPaymentMethod.id,
      );
      return selectedPaymentOption?.text;
    }
    return addPaymentOption.text;
  }, [paymentMethods, paymentOptionsList, currentPaymentMethodId]);

  return isLoading || updatePaymentInProgress ? (
    <Spinner data-testid="loading-spinner" blocking color="brand" spinnerSize="xlarge" />
  ) : (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger className="mr-4" data-testid="payment-dropdown">
          <Button
            variant="secondary"
            label={defaultPaymentLabel || 'Add a Payment Method'}
            iconRight="nav_chevron_single_down"
          />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          {paymentOptionsList.map((option) => {
            return (
              <Dropdown.Item key={option.id} onClick={option.onClick} role={option.role} data-testid={option.text}>
                {option.text}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Portal>
      </Dropdown.Root>
      {isPrecancel && (isSelfPay || isAssociate) ? (
        <Badge text={string_table.PRODUCTS_PAYMENT_FAILED} bold={true} variant="important" />
      ) : null}
    </>
  );
};

export default PaymentDropdown;
